var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { now, parseDate } from "./time";
import stepify from "./stepify";
import aggregate from "./aggregate";
import { SID } from "./constants";
// Take list of datapoints and a window (in seconds), return average rate in
// that window.
function avgrate(data, window, weekendsOff) {
    if (!data || !data.length)
        return 0;
    // convert daystamps to unixtimes
    var unixData = data.map(function (p) {
        return [parseDate(p.daystamp), p.value];
    });
    // now we can stepify the data and get a data function, df, that maps any
    // unixtime to the most recent y-value as of that unixtime:
    var df = stepify(unixData); // df is the data function
    var preTime = now() - window - 1;
    var valNow = df(now());
    var valBefore = df(preTime);
    var vdelta = valNow - valBefore;
    var divisor = weekendsOff ? window * 5 / 7 : window;
    return vdelta / divisor;
}
function autoSum(data) {
    return data.reduce(function (prev, p) {
        var last = prev[prev.length - 1];
        var sum = last ? last.value + p.value : p.value;
        return __spreadArray(__spreadArray([], prev, true), [__assign(__assign({}, p), { value: sum })], false);
    }, []);
}
// TODO: Accept per-period; default to second
export function getRollingAverageRate(g) {
    var aggregatedPoints = aggregate(g.datapoints, g.aggday);
    var summed = g.kyoom ? autoSum(aggregatedPoints) : aggregatedPoints;
    return avgrate(summed, SID * 30, g.weekends_off);
}
