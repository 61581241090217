var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, TableCell, TableRow } from "@mui/material";
import { dial, getGoalAge, getRollingAverageRate, getSettings, UNIT_SECONDS, } from "../../lib";
import React, { useEffect, useState } from "react";
import moment from "moment";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { green } from "@mui/material/colors";
function fn(n) {
    return n.toFixed(2).replace(/\.0+$/, "");
}
function getEdge(g, o) {
    var r = g.mathishard[2];
    var e = 0;
    if (r === o.min)
        e = -1;
    if (r === o.max)
        e = 1;
    return e * g.yaw;
}
export default function GoalRow(_a) {
    var goal = _a.goal, username = _a.username;
    var _b = useState(), settings = _b[0], setSettings = _b[1];
    var _c = useState(false), pendingRate = _c[0], setPendingRate = _c[1];
    var _d = useState(), arpn = _d[0], setArpn = _d[1];
    var _e = useState(0), edge = _e[0], setEdge = _e[1];
    var _f = useState(), error = _f[0], setError = _f[1];
    var success = edge === 1;
    var backgroundColor = success ? green[50] : "initial";
    useEffect(function () {
        var options = getSettings(goal);
        try {
            var newRoad = dial(goal, options);
            var newRate = newRoad && newRoad[newRoad.length - 1][2];
            var arps = getRollingAverageRate(goal);
            var arpn_1 = arps * UNIT_SECONDS[goal.runits];
            setEdge(getEdge(goal, options));
            setSettings(options);
            setPendingRate(newRate === null ? false : newRate);
            setArpn(arpn_1);
        }
        catch (e) {
            if (e instanceof Error) {
                setError(e.message);
            }
            else {
                setError("Unknown error");
            }
        }
    }, [goal]);
    var min = (settings === null || settings === void 0 ? void 0 : settings.min) === -Infinity ? "—" : "".concat(settings === null || settings === void 0 ? void 0 : settings.min, "/").concat(goal.runits);
    var max = (settings === null || settings === void 0 ? void 0 : settings.max) === Infinity ? "—" : "".concat(settings === null || settings === void 0 ? void 0 : settings.max, "/").concat(goal.runits);
    var rate = goal.mathishard[2];
    return (_jsxs(TableRow, __assign({ sx: { backgroundColor: backgroundColor } }, { children: [_jsx(TableCell, { children: _jsx("a", __assign({ href: "https://beeminder.com/".concat(username, "/").concat(goal.slug), target: "_blank", rel: "nofollow noreferrer" }, { children: goal.slug }), void 0) }, void 0), _jsx(TableCell, { children: min }, void 0), _jsx(TableCell, { children: max }, void 0), _jsx(TableCell, { children: (settings === null || settings === void 0 ? void 0 : settings.add) === 0 ? "—" : settings === null || settings === void 0 ? void 0 : settings.add }, void 0), _jsx(TableCell, { children: (settings === null || settings === void 0 ? void 0 : settings.times) === 1 ? "—" : settings === null || settings === void 0 ? void 0 : settings.times }, void 0), _jsx(TableCell, { children: (settings === null || settings === void 0 ? void 0 : settings.from) ? (_jsx("a", __assign({ href: "https://beeminder.com/".concat(username, "/").concat(settings.from), target: "_blank", rel: "nofollow noreferrer" }, { children: settings.from }), void 0)) : ("—") }, void 0), _jsx(TableCell, { children: (settings === null || settings === void 0 ? void 0 : settings.strict) ? "yes" : "no" }, void 0), _jsx(TableCell, { children: _jsxs(Box, __assign({ sx: { display: "flex", alignItems: "center" } }, { children: [fn(rate), "/", goal.runits, pendingRate && (_jsxs(_Fragment, { children: [_jsx(DoubleArrowIcon, { "aria-label": "pending change", fontSize: "inherit", sx: { pl: 1, pr: 1 } }, void 0), _jsxs("span", { children: [fn(pendingRate), "/", goal.runits] }, void 0)] }, void 0))] }), void 0) }, void 0), _jsx(TableCell, { children: arpn !== undefined &&
                    "".concat(fn(arpn)).concat((settings === null || settings === void 0 ? void 0 : settings.add) == 0 ? "" : "+" + (settings === null || settings === void 0 ? void 0 : settings.add), "/").concat(goal.runits) }, void 0), _jsx(TableCell, { children: goal.weekends_off ? "yes" : "no" }, void 0), _jsx(TableCell, { children: moment.duration(getGoalAge(goal) * 1000).humanize() }, void 0), _jsx(TableCell, { children: error }, void 0)] }), void 0));
}
