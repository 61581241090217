var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import fetch from "node-fetch";
import axios from "axios";
export function getGoalsVerbose(user, token, diffSince) {
    return __awaiter(this, void 0, Promise, function () {
        var goals, results;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getGoals(user, token)];
                case 1:
                    goals = _a.sent();
                    return [4 /*yield*/, Promise.allSettled(goals.map(function (g) {
                            return getGoal(user, token, g.slug, diffSince);
                        }))];
                case 2:
                    results = _a.sent();
                    return [2 /*return*/, results.flatMap(function (r) {
                            if (r.status === "fulfilled") {
                                return [r.value];
                            }
                            else {
                                console.log(r);
                                return [];
                            }
                        })];
            }
        });
    });
}
export function getGoals(user, token) {
    return __awaiter(this, void 0, Promise, function () {
        var url, response, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = "https://www.beeminder.com/api/v1/users/".concat(user, "/goals.json?access_token=").concat(token, "&filter=frontburner");
                    return [4 /*yield*/, fetch(url)];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    if (data === null || data === void 0 ? void 0 : data.errors) {
                        throw new Error(data.errors.message);
                    }
                    return [2 /*return*/, data];
            }
        });
    });
}
export function getGoal(user, token, slug, diffSince) {
    return __awaiter(this, void 0, Promise, function () {
        var url, response, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = "https://www.beeminder.com/api/v1/users/".concat(user, "/goals/").concat(slug, ".json?access_token=").concat(token, "&diff_since=").concat(diffSince, "&datapoints=true");
                    return [4 /*yield*/, fetch(url)];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    if (data === null || data === void 0 ? void 0 : data.errors) {
                        throw new Error(data.errors.message);
                    }
                    return [2 /*return*/, data];
            }
        });
    });
}
export function getUser(user, token) {
    var _a;
    return __awaiter(this, void 0, Promise, function () {
        var url, response, msg;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    url = "https://www.beeminder.com/api/v1/users/".concat(user, ".json");
                    return [4 /*yield*/, axios.get("".concat(url, "?access_token=").concat(token))];
                case 1:
                    response = _b.sent();
                    if (response.status !== 200) {
                        msg = "Fetch error: ".concat(response.status, " - ").concat(response.statusText, " - ").concat(url);
                        throw new Error(msg);
                    }
                    if ((_a = response.data) === null || _a === void 0 ? void 0 : _a.errors) {
                        throw new Error(response.data.errors.message);
                    }
                    return [2 /*return*/, response.data];
            }
        });
    });
}
export function updateGoal(user, token, slug, fields) {
    var _a;
    return __awaiter(this, void 0, Promise, function () {
        var url, putData, response, msg;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    url = "https://www.beeminder.com/api/v1/users/".concat(user, "/goals/").concat(slug, ".json");
                    putData = __assign(__assign({}, fields), { roadall: JSON.stringify(fields.roadall) });
                    return [4 /*yield*/, axios.put("".concat(url, "?access_token=").concat(token), putData)];
                case 1:
                    response = _b.sent();
                    if (response.status !== 200) {
                        msg = "Fetch error: ".concat(response.status, " - ").concat(response.statusText, " - ").concat(url);
                        throw new Error(msg);
                    }
                    if ((_a = response.data) === null || _a === void 0 ? void 0 : _a.errors) {
                        throw new Error(response.data.errors.message);
                    }
                    return [2 /*return*/, response.data];
            }
        });
    });
}
