import firebase from "firebase/app";
import "firebase/functions";
export function update(user, token) {
    var update = firebase.functions().httpsCallable("update");
    return update({ user: user, token: token });
}
export function remove(user, token) {
    var remove = firebase.functions().httpsCallable("remove");
    return remove({ user: user, token: token });
}
