import _, { sum } from "lodash";
import { parseDate } from "./time";
function aggregateByDate(data, reduce) {
    var sets = _.groupBy(data, function (d) { return d.daystamp; });
    return _.map(sets, function (s, k) {
        return ({
            daystamp: k,
            timestamp: parseDate(k),
            value: reduce(s.map(function (d) { return d.value; })) || 0,
        });
    });
}
var uniqueMean = function (vals) {
    var unique = Array.from(new Set(vals));
    return sum(unique) / unique.length;
};
// https://stackoverflow.com/a/53660837/937377
function median(numbers) {
    var sorted = numbers.slice().sort(function (a, b) { return a - b; });
    var middle = Math.floor(sorted.length / 2);
    if (sorted.length % 2 === 0) {
        return (sorted[middle - 1] + sorted[middle]) / 2;
    }
    return sorted[middle];
}
var methodMap = {
    "last": function (vals) { return vals.pop(); },
    "first": function (vals) { return vals.shift(); },
    "sum": function (vals) { return sum(vals); },
    "min": function (vals) { return Math.min.apply(Math, vals); },
    "max": function (vals) { return Math.max.apply(Math, vals); },
    "count": function (vals) { return vals.length; },
    "binary": function (vals) { return +!!vals.length; },
    "nonzero": function (vals) { return +!!vals.filter(function (v) { return v; }).length; },
    "truemean": function (vals) { return sum(vals) / vals.length; },
    "mean": uniqueMean,
    "uniqmean": uniqueMean,
    "median": median,
    "cap1": function (vals) { return Math.min(sum(vals), 1); },
    "square": function (vals) { return Math.pow(sum(vals), 2); },
    "triangle": function (vals) { return (sum(vals) * (sum(vals) + 1)) / 2; },
};
export default function aggregate(data, method) {
    var reducer = methodMap[method];
    if (!reducer) {
        throw new Error("Unsupported aggday method!");
    }
    return aggregateByDate(data, reducer);
}
