var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from "@mui/material";
import GoalRow from "../molecule/goalRow";
import React from "react";
export default function GoalsTable(_a) {
    var goals = _a.goals, username = _a.username;
    return (_jsx(TableContainer, __assign({ component: Paper, variant: "outlined" }, { children: _jsxs(Table, __assign({ size: "small" }, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: "Slug" }, void 0), _jsx(TableCell, { children: "Min" }, void 0), _jsx(TableCell, { children: "Max" }, void 0), _jsx(TableCell, { children: "Add" }, void 0), _jsx(TableCell, { children: "Times" }, void 0), _jsx(TableCell, { children: "From" }, void 0), _jsx(TableCell, { children: "Strict" }, void 0), _jsx(TableCell, { children: "Rate" }, void 0), _jsx(TableCell, { children: "30d Avg" }, void 0), _jsx(TableCell, { children: "Wknds Off" }, void 0), _jsx(TableCell, { children: "Age" }, void 0), _jsx(TableCell, { children: "Errors" }, void 0)] }, void 0) }, void 0), _jsx(TableBody, { children: goals.map(function (g) { return (_jsx(GoalRow, { goal: g, username: username }, g.slug)); }) }, void 0)] }), void 0) }), void 0));
}
