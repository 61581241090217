var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
export default function Issues() {
    return _jsxs(_Fragment, { children: [_jsx("h2", { children: "Known Issues & Limitations" }, void 0), _jsxs("ul", { children: [_jsxs("li", { children: ["Not all ", _jsx("a", __assign({ href: "https://help.beeminder.com/article/97-custom-goals#aggday" }, { children: "aggregation methods" }), void 0), " are supported. Unsupported methods include mode, trimmean, clocky, and skatesum."] }, void 0), _jsx("li", { children: "The aggregated value of a goal's initial day is considered the starting value of the road and does not otherwise influence dialing." }, void 0), _jsx("li", { children: "This tool assumes the akrasia horizon is eight days instead of seven in order to avoid needing to take the user's timezone into account." }, void 0), _jsx("li", { children: "Logging into the autodialer in one window will log you out in all other windows." }, void 0), _jsx("li", { children: "Goals with an end state defined in terms of end date and end value, leaving end rate undefined, are unsupported and will not be dialed." }, void 0), _jsx("li", { children: "Odometer-type goals are unsupported and will not be dialed." }, void 0)] }, void 0)] }, void 0);
}
