function parseOptionFloat(haystack, hashtag, fallback) {
    var match = parseHashtag(haystack, hashtag, "(-?\\d*\\.?\\d+)");
    return match ? parseFloat(match) : fallback;
}
function parseHashtag(haystack, hashtag, pattern) {
    var matches = haystack.match(new RegExp("#".concat(hashtag, "=").concat(pattern), "i"));
    return matches === null || matches === void 0 ? void 0 : matches[1];
}
export function getSettings(g) {
    var t = "".concat(g.fineprint, " ").concat(g.title);
    return {
        autodial: t.includes("#autodial"),
        min: parseOptionFloat(t, "autodialMin", -Infinity),
        max: parseOptionFloat(t, "autodialMax", Infinity),
        strict: t.includes("#autodialStrict"),
        add: parseOptionFloat(t, "autodialAdd", 0),
        times: parseOptionFloat(t, "autodialTimes", 1),
        from: parseHashtag(t, "autodialFrom", "(-?[\\w-]+)"),
    };
}
