import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Tags from "./tags";
import { LinearProgress } from "@mui/material";
import GoalsTable from "./goalsTable";
import React from "react";
import useGoals from "../../lib/useGoals";
import useParams from "../../lib/useParams";
import { useIsFetching } from "react-query";
export default function StepTwo() {
    var _a;
    var params = useParams();
    var goals = useGoals();
    var isFetching = useIsFetching();
    return (_jsxs(_Fragment, { children: [_jsx("h3", { children: "Step 2: Configure specific goals to use the autodialer" }, void 0), _jsx("p", { children: "Add one or more of the following tags to the fineprint / description of the goals you wish to autodial:" }, void 0), _jsx(Tags, {}, void 0), params.user && ((_a = goals.data) === null || _a === void 0 ? void 0 : _a.length) && (_jsxs(_Fragment, { children: [_jsx("p", { children: "Here are your goals for which autodialing is enabled:" }, void 0), isFetching ? _jsx(LinearProgress, {}, void 0) : null, _jsx(GoalsTable, { goals: goals.data, username: params.user }, void 0)] }, void 0))] }, void 0));
}
