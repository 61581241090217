var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { now } from "./time";
import { AKRASIA_HORIZON, SID, UNIT_SECONDS } from "./constants";
import { getRollingAverageRate } from "./getRollingAverageRate";
import { fuzzyEquals } from "./fuzzyEquals";
function clip(x, min, max) {
    var _a;
    if (min > max)
        _a = [max, min], min = _a[0], max = _a[1];
    return x < min ? min : x > max ? max : x;
}
// Goal maturity is defined in terms of the length of the goal's road,
// as a percentage of one month, capped at 100%.
function getGoalMaturity(g, opts) {
    var t = now();
    var fromGoal = opts.fromGoal;
    var start = Math.max(g.fullroad[0][0], fromGoal ? fromGoal.fullroad[0][0] : 0);
    var len = t - start;
    return Math.min(len / (SID * 30), 1);
}
function calculateNewRate(g, opts) {
    var _a = opts.min, min = _a === void 0 ? -Infinity : _a, _b = opts.max, max = _b === void 0 ? Infinity : _b, _c = opts.strict, strict = _c === void 0 ? false : _c, _d = opts.add, add = _d === void 0 ? 0 : _d, _e = opts.times, times = _e === void 0 ? 1 : _e, fromGoal = opts.fromGoal;
    var neverLess = strict && g.yaw == 1;
    var neverMore = strict && g.yaw == -1;
    var strictMin = neverLess && g.rate !== null ? Math.max(min, g.rate) : min;
    var strictMax = neverMore && g.rate !== null ? Math.min(max, g.rate) : max;
    var rateSeconds = UNIT_SECONDS[g.runits];
    var averagePerSecond = getRollingAverageRate(fromGoal !== null && fromGoal !== void 0 ? fromGoal : g);
    var oldRate = g.mathishard[2];
    var newRate = averagePerSecond * rateSeconds * times + add;
    var maturity = getGoalMaturity(g, opts);
    var rateDiff = oldRate - newRate;
    var modulatedRate = oldRate - rateDiff * maturity;
    return clip(modulatedRate, strictMin, strictMax);
}
function shouldDial(g) {
    if (g.odom)
        throw new Error("Odometer-type goals are not supported");
    var roadallEnd = g.roadall[g.roadall.length - 1];
    if (roadallEnd[2] === null)
        throw new Error("Goals without explicit end rates are not supported");
    var fullroadEnd = g.fullroad[g.fullroad.length - 1];
    // If the goal ends within the akrasia horizon, don't dial it.
    if (fullroadEnd[0] <= now() + AKRASIA_HORIZON)
        throw new Error("Goal ends too soon to dial");
}
function buildRoad(g, newRate) {
    var t = now();
    var tail = g.roadall.slice(0, -1);
    var lastRow = g.roadall[g.roadall.length - 1];
    var lastRowModified = [lastRow[0], lastRow[1], newRate];
    var fullTail = g.fullroad.slice(0, -1);
    var unixTimes = fullTail.map(function (r) { return r[0]; });
    var shouldAddBoundary = !unixTimes.some(function (ut) {
        return ut >= t + AKRASIA_HORIZON;
    });
    if (!shouldAddBoundary) {
        return __spreadArray(__spreadArray([], tail, true), [lastRowModified], false);
    }
    return __spreadArray(__spreadArray([], tail, true), [[t + AKRASIA_HORIZON, null, lastRow[2]], lastRowModified], false);
}
export function dial(g, opts) {
    if (opts === void 0) { opts = {}; }
    shouldDial(g);
    var newRate = calculateNewRate(g, opts);
    var oldRate = g.mathishard[2];
    if (fuzzyEquals(newRate, oldRate))
        return false;
    return buildRoad(g, newRate);
}
