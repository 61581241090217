var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
export default function Acknowledgements() {
    return (_jsxs(_Fragment, { children: [_jsx("h2", { children: "Acknowledgements" }, void 0), _jsx("p", { children: "Special thanks to:" }, void 0), _jsxs("ul", { children: [_jsxs("li", { children: [_jsx("a", __assign({ href: "https://www.beeminder.com/aboutus" }, { children: "Mary Renaud, Dept. of Treasury at Beeminder" }), void 0), ", for creating the original autodialer and sharing her invaluable advice during the development of this tool."] }, void 0), _jsxs("li", { children: [_jsx("a", __assign({ href: "https://www.beeminder.com/aboutus" }, { children: "Daniel Reeves, co-founder & CEO of Beeminder" }), void 0), ", for assisting with the specification and development of the tool."] }, void 0), _jsxs("li", { children: [_jsx("a", __assign({ href: "https://www.beeminder.com/home" }, { children: "The Beeminder company" }), void 0), " for permitting code from their codebase to be copied into this project."] }, void 0), _jsxs("li", { children: [_jsx("a", __assign({ href: "https://icons8.com/" }, { children: "Icons8" }), void 0), " for providing the favicon."] }, void 0)] }, void 0), _jsxs("p", { children: [_jsx("a", __assign({ href: "https://github.com/TaskRatchet/clients/tree/main/apps/autodial" }, { children: "This open-source tool" }), void 0), " ", "is maintained by ", _jsx("a", __assign({ href: "https://nathanarthur.com" }, { children: "Nathan Arthur" }), void 0), " ", "and ", _jsx("a", __assign({ href: "https://pinepeakdigital.com/" }, { children: "Pine Peak Digital" }), void 0), ". See also:"] }, void 0), _jsxs("ul", { children: [_jsx("li", { children: _jsx("a", __assign({ href: "https://taskratchet.com" }, { children: "TaskRatchet" }), void 0) }, void 0), _jsx("li", { children: _jsx("a", __assign({ href: "https://bm.taskratchet.com" }, { children: "Custom Beeminder Dashboard" }), void 0) }, void 0)] }, void 0)] }, void 0));
}
