var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { useEffect } from "react";
import useUpdate from "../../lib/useUpdate";
import useRemove from "../../lib/useRemove";
import useGoals from "../../lib/useGoals";
import useParams from "../../lib/useParams";
import useIsAuthenticated from "../../lib/useIsAuthenticated";
export default function StepOne() {
    var _a = useParams(), user = _a.user, token = _a.token, disable = _a.disable;
    var update = useUpdate();
    var remove = useRemove();
    var goals = useGoals();
    var isAuthenticated = useIsAuthenticated();
    var _b = process.env, _c = _b.REACT_APP_APP_URL, REACT_APP_APP_URL = _c === void 0 ? "" : _c, _d = _b.REACT_APP_BM_CLIENT_ID, REACT_APP_BM_CLIENT_ID = _d === void 0 ? "" : _d;
    var redirectUri = encodeURIComponent(REACT_APP_APP_URL);
    var enableUrl = "https://www.beeminder.com/apps/authorize?client_id=".concat(REACT_APP_BM_CLIENT_ID, "&redirect_uri=").concat(redirectUri, "&response_type=token");
    var disableUrl = "/?access_token=".concat(token, "&username=").concat(user, "&disable=true");
    useEffect(function () {
        if (!user || !token)
            return;
        if (disable) {
            remove.mutate({ user: user, token: token });
        }
        else {
            update.mutate({ user: user, token: token });
        }
    }, [user, token]);
    return (_jsxs(_Fragment, { children: [_jsxs("h3", { children: ["Step 1: Connect the autodialer to your", " ", _jsx("a", __assign({ href: "https://beeminder.com" }, { children: "Beeminder" }), void 0), " account"] }, void 0), update.error && _jsx(Alert, __assign({ severity: "error" }, { children: update.error.message }), void 0), remove.error && _jsx(Alert, __assign({ severity: "error" }, { children: remove.error.message }), void 0), goals.error && _jsx(Alert, __assign({ severity: "error" }, { children: goals.error.message }), void 0), remove.isSuccess && (_jsxs(Alert, __assign({ severity: "success" }, { children: ["The autodialer has been disabled for Beeminder user ", user] }), void 0)), !isAuthenticated && (_jsx(LoadingButton, __assign({ variant: "contained", color: "primary", href: enableUrl, loading: goals.isLoading || update.isLoading }, { children: "Enable Autodialer" }), void 0)), isAuthenticated && (_jsxs(_Fragment, { children: [_jsxs("p", { children: ["Connected Beeminder user:", " ", _jsx("strong", { children: _jsx("a", __assign({ href: "https://beeminder.com/".concat(user), target: "_blank", rel: "nofollow noreferrer" }, { children: user }), void 0) }, void 0)] }, void 0), _jsx(LoadingButton, __assign({ variant: "outlined", color: "secondary", href: disableUrl, loading: remove.isLoading }, { children: "Disable Autodialer" }), void 0)] }, void 0))] }, void 0));
}
