var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import "./App.css";
import { init } from "./lib/firebase";
import { Alert, Container, } from "@mui/material";
import Acknowledgements from "./component/organism/acknowledgements";
import Issues from "./component/organism/issues";
import StepThree from "./component/organism/stepThree";
import StepTwo from "./component/organism/stepTwo";
import StepOne from "./component/organism/stepOne";
init();
function App() {
    return _jsxs(Container, __assign({ className: "App" }, { children: [_jsx("h1", { children: "Beeminder Autodialer" }, void 0), _jsxs(Alert, __assign({ severity: "warning" }, { children: ["This tool is in beta and may contain bugs. Use at your own risk. If you encounter any bugs that are not documented in the known limitations section below, please report the issue to", " ", _jsx("a", __assign({ href: "mailto:nathan@taskratchet.com" }, { children: "nathan@taskratchet.com" }), void 0), ". Thank you!"] }), void 0), _jsx("p", { children: "The Beeminder autodialer will automatically adjust the rate on your goals based on your historical performance." }, void 0), _jsx("h2", { children: "Instructions" }, void 0), _jsx(StepOne, {}, void 0), _jsx(StepTwo, {}, void 0), _jsx(StepThree, {}, void 0), _jsx(Issues, {}, void 0), _jsx(Acknowledgements, {}, void 0)] }), void 0);
}
export default App;
